.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {

}

.App-header {
  background-color: #bdcff3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(245, 186, 108);
}

.App-link {
  color: #61dafb;
}

h1{
 font-family: Niconne;
 color: rgb(245, 186, 108);
 text-shadow: 2px 2px 4px black;
}

.Home{
  background-color: black;
}

.Home h3{
  color: rgb(245, 186, 108);
  font-family: 'Kaushan Script';
  text-shadow: 2px 2px 2px black;
}

.Home p{
  font-family: 'Courgette';
}

.Menu{
  background-color: rgb(122, 51, 51);
}

.Menu h2, h3{
  font-family: "Courgette";
  color: rgb(245, 186, 108);
  text-shadow: 2px 2px 2px black;
}

.Menu p{
  font-family: 'Cinzel', serif;
  font-size: 20px;
  color: white;
}

.Menu br{
  background-color: white;
  color: white;
}

.navbar, .navbar-brand, .navbar-end, .navbar-menu, .navbar-start {
  padding-top: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  align-items: stretch !important;
  display: flex !important;
  background-color: black;
}

.navbar-menu {
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.navbar-item {
  font-family: "PT Serif";
  margin-top: 10px;
  margin-left: 10px;
  color: rgb(243, 176, 113);
  display: flex !important;
}

.is-active{
  color: rgb(199, 139, 28);
}

.navbar-item:hover{
  text-decoration: none;
  color: rgb(255, 166, 0);
}

.navbar-end {
  justify-content: flex-end !important;
  margin-left: auto !important;
}

.fixedSection{
  background-image: url("./images/chilllounge.jpg");
  background-attachment: fixed;
  min-height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.smokeshopsection{
  background-image: url("./images/smokeshop/shop1.jpg");
  min-height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Form {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.footer{
  color: whitesmoke;
  background-color: black;
}

.form-group, .form-control {width : 100%;}
